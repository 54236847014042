import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import { isNullOrWhiteSpace } from '@/utils';
import {
  Tag,
  Map,
  SearchHeader,
  Sidebar,
  ClubCard,
  Loader,
  MobileFilter,
  Advertisement,
} from '@/components';

export default {
  metaInfo: {
    title: 'Clubs - IKwatersport',
  },
  name: 'ClubSearch',
  components: {
    [Tag.name]: Tag,
    [Map.name]: Map,
    [SearchHeader.name]: SearchHeader,
    [Sidebar.name]: Sidebar,
    [ClubCard.name]: ClubCard,
    [Loader.name]: Loader,
    [MobileFilter.name]: MobileFilter,
    [Advertisement.name]: Advertisement,
  },
  props: {},
  data: () => ({
    title: 'Clubs',
    searchPlaceholder: 'Zoek een club...',
    mobileFilterActive: false,
    possibleToUpdateUrl: false,
    page: 1,
    mapViewActive: false,
    created: false,
    map: {
      zoom: 8,
      points: [],
      center: [51, 3],
    },
    advertisement: null,
    windowWidth: window.innerWidth,
  }),
  computed: {
    isTablet() {
      if (this.windowWidth < 769) {
        return true;
      }
      return false;
    },
    ...mapGetters('advertisement', {
      bestMatch: 'getBestmatch',
    }),
    ...mapGetters('club', {
      clubs: 'getClubList',
      markers: 'getClubMarkers',
      isLoading: 'getLoadingState',
      buttonIsLoading: 'getButtonLoadingState',
      getClubFilter: 'getClubFilter',
    }),
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    ...mapActions('advertisement', {
      initialiseAdvertisementBestmatch: 'FETCH_DATA_ADVERTISEMENT_BESTMATCH',
    }),
    ...mapMutations('club', {
      clearClubList: 'CLEAR_CLUB_LIST',
      setClubFilterDiscipline: 'SET_CLUB_FILTER_DISCIPLINE',
      setClubFilterQuery: 'SET_CLUB_FILTER_QUERY',
      clearClubFilterQuery: 'CLEAR_CLUB_FILTER_QUERY',
      setClubFilterLocation: 'SET_CLUB_FILTER_LOCATION',
    }),
    ...mapActions('club', {
      getClubs: 'FETCH_DATA_CLUB_LIST',
      getClubMarkers: 'FETCH_DATA_CLUB_MARKERS',
    }),
    ...mapActions('discipline', {
      fetchDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),

    toggleMapView() {
      this.mapViewActive = !this.mapViewActive;
      if (this.mapViewActive) {
        this.queryClubMarkers();
      } else {
        this.clearClubList();
        this.page = 1;
        const filter = _.clone(this.getClubFilter);
        filter.page = 1;
        this.getClubs(filter);
      }
    },
    toggleMobileFilter() {
      this.mobileFilterActive = !this.mobileFilterActive;
    },
    search(searchQuery) {
      this.setClubFilterQuery(searchQuery);
    },
    updateRouteQuery() {
      const filter = this.getClubFilter;
      const queryObject = {};
      if (!isNullOrWhiteSpace(filter.query)) {
        queryObject.q = filter.query;
      }

      if (filter.discipline !== 0 && filter.discipline !== null) {
        this.discipline = this.disciplineList.find((d) => d.id === filter.discipline);
        queryObject.discipline = this.discipline.name;
      }

      if (!isNullOrWhiteSpace(filter.location)) {
        queryObject.locatie = filter.location;
      }

      this.$router.replace({ query: queryObject }).catch(() => { });
    },
    updateClubFilterFromQuery() {
      const queryObject = this.$route.query;

      if (!isNullOrWhiteSpace(queryObject.q)) {
        this.setClubFilterQuery(queryObject.q);
      }

      if (!isNullOrWhiteSpace(queryObject.discipline)) {
        this.discipline = this.disciplineList.find((d) => d.name.includes(queryObject.discipline));
        this.setClubFilterDiscipline(this.discipline.id);
      }

      if (!isNullOrWhiteSpace(queryObject.locatie)) {
        this.setClubFilterLocation(queryObject.locatie);
      }
    },
    loadMoreClubs() {
      this.page += 1;

      const filter = _.clone(this.getClubFilter);
      filter.page = this.page;
      filter.loadButton = true;
      this.getClubs(filter);
    },
    queryClubMarkers() {
      const filter = _.clone(this.getClubFilter);
      filter.pageSize = 100000;
      filter.page = 1;
      this.getClubMarkers(filter);
    },

    async getClubFilterWatchFunction(updatedClubFilter) {
      // when the filter changes reset this.page and clear the clubs
      this.clearClubList();
      this.page = 1;

      const filter = updatedClubFilter;
      filter.page = this.page;

      if (this.mapViewActive) {
        this.queryClubMarkers();
      } else {
        this.getClubs(filter);
      }

      this.updateRouteQuery();
    },
  },
  created() {
    const params = {
      tags: [],
    };
    this.initialiseAdvertisementBestmatch(params)
      .then(() => {
        this.advertisement = this.bestMatch;
      });

    this.created = true;
    this.fetchDisciplineList()
      .then(async () => {
        this.updateClubFilterFromQuery();
        await this.getClubFilterWatchFunction(this.getClubFilter);
        this.created = false;
      });
  },
  watch: {
    $route() {
      this.updateRouteQuery();
    },
    getClubFilter: {
      handler(updatedClubFilter) {
        if (!this.created) { this.getClubFilterWatchFunction(updatedClubFilter); }
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
